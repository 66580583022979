import "./App.css";
import RouterContainer from "./RouterContainer/RouterContainer";

function App() {
  return (
    <div className="App">
      <RouterContainer />
    </div>
  );
}

export default App;
