import React, { useState } from "react";
import logo from "../../Assets/images/logo3.svg";
import "../../App.css";
import { useRef } from "react";
import {
  Description,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";

import messsage from "../../Assets/images/message.png";
import icon from "../../Assets/images/social-icon.png";
import icon1 from "../../Assets/images/social-icon1.png";
import icon2 from "../../Assets/images/social-icon2.png";
import icon3 from "../../Assets/images/social-icon3.png";
import icon4 from "../../Assets/images/social-icon4.png";
import appstoreimg from "../../Assets/images/appstoreimg.png";
import gplayimg from "../../Assets/images/gplayimg.png";
import location from "../../Assets/images/location.png";
import { Link } from "react-router-dom";

function Footer() {
  const linkRef = useRef(null);
  const [Open, setOpen] = useState(false);
  return (
    <>
      <section>
        <div className="bg-[#1a1a1a]">
          <div className="containers sm1:pt-14 pt-10">
            <div className="grid grid-cols-12   md:gap-x-0 sm1:gap-x-2 ">
              <div className="md1:col-span-2 sm1:col-span-4 col-span-12 ">
                <div className="md1:pt-0 sm1:pt-14 pt-0 ">
                  <img
                    src={logo}
                    alt="logo"
                    className="2xl:w-[220px] 2xl:h-[220px] xl:w-52 xl:h-52 lg:w-44 lg:h-44 md:w-40 md:h-40 sm1:w-36 sm1:h-36 xs:w-40 xs:h-40 w-36 h-36 mt-3"
                  />
                </div>
              </div>
              <div className="md1:col-span-10 sm1:col-span-8 col-span-12">
                <div className="grid grid-cols-12 ">
                  <div className="md1:col-span-2  sm1:col-span-6 col-span-12 sm1:pt-6 pt-4">
                    <div>
                      <h2 className="fonts-500 2xl:text-xl xl:text-xl lg:text-lg md:text-sm sm1:text-xs text-base leading-8 text-white underline underline-offset-[9px] ">
                        Explore Us
                      </h2>
                      <ul className="text-[#828282] sm1:py-6 py-5 fonts-400 xl:text-base lg:text-sm sm1:text-xs text-sm">
                        <li className="">
                          <Link
                            to="/about_us"
                            className="text-decoration-none cursor-pointer"
                          >
                            About Us
                          </Link>
                        </li>
                        <li className="py-3">
                          <a
                            href="/#faqs"
                            className="text-decoration-none cursor-pointer"
                          >
                            FAQs
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="md1:col-span-3  sm1:col-span-6 col-span-12  sm1:pt-6 pt-4">
                    <div>
                      <h2 className="fonts-500 2xl:text-xl xl:text-xl lg:text-lg md:text-sm sm1:text-xs text-base leading-8 text-white underline underline-offset-[9px]">
                        service locations
                      </h2>
                      {/* <div className="border-b border-white 2xl:w-56 xl:w-48 lg:w-44 md:w-36 w-40 sm:mt-1"></div> */}

                      <div className="text-[#828282] sm1:py-6 py-5">
                        <div className="flex items-center">
                          <img
                            src={location}
                            alt="location img"
                            className="xl:w-6 xl:h-6 sm1:w-4 sm1:h-4 w-6 h-6 cursor-pointer"
                          />

                          <p className="text-white xl:text-base lg:text-sm sm1:text-[11px] text-sm fonts-500  md1:ml-2 ml-1">
                            Hyderabad
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md1:col-span-4 sm1:col-span-6 col-span-12  sm1:pt-6 pt-4">
                    <div>
                      <h2 className="fonts-500 2xl:text-xl xl:text-xl lg:text-lg md:text-sm sm1:text-xs text-base leading-8 text-white underline underline-offset-[9px]">
                        Customer Support
                      </h2>

                      <div className="text-[#828282] sm1:py-6 py-5">
                        <div className="flex items-center">
                          <img
                            src={messsage}
                            alt="message"
                            className="xl:w-6 xl:h-6 sm1:w-4 sm1:h-4 w-6 h-6 cursor-pointer"
                          />

                          <a
                            href="support@promarvel.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            ref={linkRef}
                            className="text-white xl:text-base lg:text-sm sm1:text-[11px] text-sm fonts-500 underline md1:ml-2 ml-1"
                          >
                            support@promarvel.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md1:col-span-3  sm1:col-span-6 col-span-12 sm1:pt-6 pt-4">
                    <div>
                      <h2 className="fonts-500 2xl:text-xl xl:text-xl lg:text-lg md:text-sm sm1:text-xs text-base leading-8 text-white  underline underline-offset-[9px]">
                        Social Links
                      </h2>
                      <div className=" sm1:py-6 py-5">
                        <div className="flex flex-wrap space-y-1  items-center md:gap-x-2.5 gap-x-1">
                          <a
                            className="border rounded-full border-[#303030] xl:p-2 lg:p-1.5 md:p-1.5  sm1:p-1 p-2 cursor-pointer  "
                            href="https://www.instagram.com/promarvelsolutions?igsh=cXZreXBqd3Q1Z2Y0&utm_source=qr"
                            target="_blank"
                            rel="noopener noreferrer"
                            ref={linkRef}
                          >
                            <img
                              src={icon1}
                              alt="insta icon"
                              className="xl:w-5 xl:h-5 md:w-4 md:h-4 sm1:w-4 sm1:h-4 w-4 h-4"
                            />
                          </a>
                          <a
                            className="border rounded-full border-[#303030] xl:p-2 lg:p-1.5 md:p-1.5 sm1:p-1 p-2  cursor-pointer"
                            href="https://x.com/ThePromarvel?t=kfRxyhS6kcPmKM6BWiWBXQ&s=09"
                            target="_blank"
                            rel="noopener noreferrer"
                            ref={linkRef}
                          >
                            <img
                              src={icon2}
                              alt=" icon"
                              className="xl:w-5 xl:h-5 md:w-4 md:h-4 sm1:w-4 sm1:h-4 w-4 h-4"
                            />
                          </a>
                          <a
                            className="border rounded-full border-[#303030] xl:p-2 lg:p-1.5 md:p-1.5 sm1:p-1 p-2 cursor-pointer"
                            href="https://youtube.com/@promarvelsolutions?si=zGFNcjdbSwReEQ4b"
                            target="_blank"
                            rel="noopener noreferrer"
                            ref={linkRef}
                          >
                            <img
                              src={icon3}
                              alt="youtube icon"
                              className="xl:w-5 xl:h-5 md:w-4 md:h-4 sm1:w-4 sm1:h-4 w-4 h-4"
                            />
                          </a>
                          <a
                            className="border rounded-full border-[#303030] xl:p-2 lg:p-1.5 md:p-1.5 sm1:p-1 p-2 cursor-pointer"
                            href="https://www.linkedin.com/company/promarvel/"
                            target="_blank"
                            rel="noopener noreferrer"
                            ref={linkRef}
                          >
                            <img
                              src={icon4}
                              alt="linkdin icon"
                              className="xl:w-5 xl:h-5 md:w-4 md:h-4 sm1:w-4 sm1:h-4 w-4 h-4"
                            />
                          </a>
                        </div>
                        <div>
                          <img
                            src={appstoreimg}
                            alt="app store img"
                            className="xl:w-32 xl:h-10 lg:w-28 lg:h-9 md:w-24 md:h-8 sm1:w-20 sm1:h-7 w-32 h-10 my-5 "
                            onClick={() => {
                              setOpen(true);
                            }}
                          />
                          <img
                            src={gplayimg}
                            alt="google play img"
                            className="xl:w-32 xl:h-10 lg:w-28 lg:h-9 md:w-24 md:h-8 sm1:w-20 sm1:h-7 w-32 h-10 sm1:my-0 my-5"
                            onClick={() => {
                              setOpen(true);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t border-[#242424] " />
        </div>
      </section>
      <section>
        <div className="bg-[#1a1a1a]">
          <div className="containers">
            <p className="text-decoration-none fonts-medium md:text-base text-sm text-[10px] text-white text-center md:py-5 sm:py-2 py-3 ">
              Copyright © 2024 Promarvel Solutions opc pvt ltd. | All Rights
              Reserved
            </p>
          </div>
        </div>
      </section>
      <Dialog
        open={Open}
        onClose={() => setOpen(false)}
        className="relative z-50"
      >
        <DialogBackdrop className="fixed inset-0 bg-black/30 backdrop-blur-[2px]" />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className="max-w-2xl space-y-3 rounded-xl bg-[#1E1E1E] text-white py-7 px-7 ">
            <DialogTitle className="font-bold text-center text-base text-[#c9c9c9] fonts-600">
              Almost there!{" "}
            </DialogTitle>
            <Description className="text-sm text-center text-[#c9c9c9] mt-1 fonts-300">
              "The PROMARVEL app is launching soon. Stay tuned for the big
              reveal and be among the first to enjoy hassle-free home services
              at your fingertips!"
            </Description>
            <div className="flex justify-center">
              <button
                className="px-16 py-2 bg-white text-[#121212] text-base rounded-xl fonts-500  mt-4"
                onClick={() => setOpen(false)}
              >
                Okay
              </button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
export default Footer;
