import React from "react";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Homepage from "../pages/Homepage/Homepage";
import MainLayout from "../components/MainLayouts/MainLayouts";
import Policypage from "../pages/Policypage/Policypage";
import Termspage from "../pages/Termspage/Termspage";
import AboutUsPage from "../pages/AboutUsPage/AboutUsPage";

function RouterContainer() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <MainLayout>
                <Homepage />
              </MainLayout>
            }
          />

          <Route
            path="/privacy_policy"
            element={
              <MainLayout>
                <Policypage />
              </MainLayout>
            }
          />
          <Route
            path="/terms_of_use"
            element={
              <MainLayout>
                <Termspage />
              </MainLayout>
            }
          />
          <Route
            path="/about_us"
            element={
              <MainLayout>
                <AboutUsPage />
              </MainLayout>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default RouterContainer;
