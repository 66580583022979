function Policypage() {
  return (
    <>
      <div className="bg-[#0f0f0f] text-white">
        <div className="containers py-10">
          <div className="border sm:px-10 px-5 md:py-16 py-10 rounded-2xl ">
            <h2 className="text-3xl font-bold">PRIVACY POLICY</h2>
            <h6 className="py-4 font-medium text-sm">
              Last updated October 22, 2024
            </h6>
            <div className="pt-8 text-sm space-y-5">
              <p>
                This Privacy Notice for promarvel solutions opc private limited
                (doing business as Promarvel) ("we," "us," or "our"), describes
                how and why we might access, collect, store, use, and/or share
                ("process") your personal information when you use our services
                ("Services"), including when you:
              </p>
              <ul className="space-y-5 list-disc ml-10">
                <li>
                  Visit our website at{" "}
                  <a
                    href="https://www.promarvel.com"
                    className="underline text-[#3de3b7]"
                  >
                    https://www.promarvel.com
                  </a>
                  , or any website of ours that links to this Privacy Notice
                </li>
                <li>
                  Download and use our mobile application (promarvel), or any
                  other application of ours that links to this Privacy Notice
                </li>
                <li>
                  Use promarvel. At PROMARVEL, we value your privacy and are
                  committed to protecting your personal information. This
                  Privacy Policy outlines how we collect, use, and safeguard
                  your data when you use our website and app for booking home
                  services, including appliance repair, plumbing, painting,
                  cleaning, pest control, and beauty treatments. We ensure your
                  information is handled with the utmost care and in compliance
                  with applicable laws.
                </li>
                <li>
                  Engage with us in other related ways, including any sales,
                  marketing, or events
                </li>
              </ul>

              <p>
                <span className="font-bold"> Questions or concerns? </span>{" "}
                Reading this Privacy Notice will help you understand your
                privacy rights and choices. We are responsible for making
                decisions about how your personal information is processed. If
                you do not agree with our policies and practices, please do not
                use our Services. If you still have any questions or concerns,
                please contact us at privacy@promarvel.com.
              </p>
            </div>
            <div className="pt-8 text-sm space-y-5">
              <h2 className="text-lg font-bold mt-3">SUMMARY OF KEY POINTS</h2>

              <p className="italic">
                This summary provides key points from our Privacy Notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our{" "}
                <a href="#toc" className="underline text-[#3de3b7]">
                  table of contents
                </a>{" "}
                below to find the section you are looking for.
              </p>
              <p>
                <span className="font-medium ">
                  What personal information do we process?{" "}
                </span>
                When you visit, use, or navigate our Services, we may process
                personal information depending on how you interact with us and
                the Services, the choices you make, and the products and
                features you use. Learn more about{" "}
                <a href="#personalinfo" className="underline text-[#3de3b7]">
                  {" "}
                  personal information you disclose to us{" "}
                </a>
                .
              </p>
              <p>
                <span className="font-medium">
                  {" "}
                  Do we process any sensitive personal information?
                </span>{" "}
                Some of the information may be considered "special" or
                "sensitive" in certain jurisdictions, for example your racial or
                ethnic origins, sexual orientation, and religious beliefs. We
                may process sensitive personal information when necessary with
                your consent or as otherwise permitted by applicable law. Learn
                more about{" "}
                <a href="#sensitiveinfo" className="underline text-[#3de3b7]">
                  sensitive information we process
                </a>
                .
              </p>
              <p>
                <span className="font-medium">
                  {" "}
                  Do we collect any information from third parties?{" "}
                </span>{" "}
                We do not collect any information from third parties.
              </p>
              <p>
                <span className="font-medium">
                  How do we process your information?{" "}
                </span>{" "}
                We process your information to provide, improve, and administer
                our Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent. We process
                your information only when we have a valid legal reason to do
                so. Learn more about{" "}
                <a href="#infouse" className="underline text-[#3de3b7]">
                  {" "}
                  how we process your information
                </a>
                .
              </p>
              <p>
                <span className="font-medium">
                  {" "}
                  In what situations and with which parties do we share personal
                  information?{" "}
                </span>{" "}
                We may share information in specific situations and with
                specific third parties. Learn more about{" "}
                <a href="#whoshare" className="underline text-[#3de3b7]">
                  {" "}
                  when and with whom we share your personal information
                </a>
                .
              </p>
              <p>
                <span className="font-medium">
                  How do we keep your information safe?{" "}
                </span>{" "}
                We have adequate organizational and technical processes and
                procedures in place to protect your personal information.
                However, no electronic transmission over the internet or
                information storage technology can be guaranteed to be 100%
                secure, so we cannot promise or guarantee that hackers,
                cybercriminals, or other unauthorized third parties will not be
                able to defeat our security and improperly collect, access,
                steal, or modify your information. Learn more about{" "}
                <a href="#infosafe" className="underline text-[#3de3b7]">
                  {" "}
                  how we keep your information safe
                </a>
                .
              </p>
              <p>
                <span className="font-medium">What are your rights? </span>{" "}
                Depending on where you are located geographically, the
                applicable privacy law may mean you have certain rights
                regarding your personal information. Learn more about{" "}
                <a href="#privacyrights" className="underline text-[#3de3b7]">
                  {" "}
                  your privacy rights
                </a>
                .
              </p>
              <p>
                <span className="font-medium">
                  {" "}
                  How do you exercise your rights?{" "}
                </span>{" "}
                The easiest way to exercise your rights is by submitting a{" "}
                <a
                  href="https://app.termly.io/notify/3c48b622-dd7f-4242-955b-366ad7ec3265"
                  className="underline text-[#3de3b7]"
                >
                  data subject access request
                </a>
                , or by contacting us. We will consider and act upon any request
                in accordance with applicable data protection laws.
              </p>
              <p>
                <span className="font-medium">
                  Want to learn more about what we do with any information we
                  collect?{" "}
                </span>{" "}
                <a href="#toc" className="underline text-[#3de3b7]">
                  {" "}
                  Review the Privacy Notice in full
                </a>
                .
              </p>
            </div>
            <div className="pt-8 text-sm space-y-1" id="toc">
              <h2 className="text-lg font-bold mt-3 mb-5">
                TABLE OF CONTENTS{" "}
              </h2>
              <ol>
                <li>
                  <a href="#infocollect" className="underline text-[#3de3b7]">
                    1. WHAT INFORMATION DO WE COLLECT?
                  </a>
                </li>
                <li>
                  <a href="#infouse" className="underline text-[#3de3b7]">
                    2. HOW DO WE PROCESS YOUR INFORMATION?
                  </a>
                </li>
                <li>
                  <a href="#whoshare" className="underline text-[#3de3b7]">
                    3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                  </a>
                </li>
                <li>
                  <a href="#cookies" className="underline text-[#3de3b7]">
                    4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                  </a>
                </li>
                <li>
                  <a href="#inforetain" className="underline text-[#3de3b7]">
                    5. HOW LONG DO WE KEEP YOUR INFORMATION?
                  </a>
                </li>
                <li>
                  <a href="#infosafe" className="underline text-[#3de3b7]">
                    6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                  </a>
                </li>
                <li>
                  <a href="#infominors" className="underline text-[#3de3b7]">
                    7. DO WE COLLECT INFORMATION FROM MINORS?
                  </a>
                </li>
                <li>
                  <a href="#privacyrights" className="underline text-[#3de3b7]">
                    {" "}
                    8. WHAT ARE YOUR PRIVACY RIGHTS?
                  </a>
                </li>
                <li>
                  <a href="#DNT" className="underline text-[#3de3b7]">
                    {" "}
                    9. CONTROLS FOR DO-NOT-TRACK FEATURES
                  </a>
                </li>
                <li>
                  <a href="#policyupdates" className="underline text-[#3de3b7]">
                    {" "}
                    10. DO WE MAKE UPDATES TO THIS NOTICE?
                  </a>
                </li>
                <li>
                  <a href="#contact" className="underline text-[#3de3b7]">
                    {" "}
                    11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </a>
                </li>
                <li>
                  <a href="#request" className="underline text-[#3de3b7]">
                    {" "}
                    12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                    COLLECT FROM YOU?
                  </a>
                </li>
              </ol>
            </div>
            <div className="pt-8 text-sm space-y-5" id="infocollect">
              <h2 className="text-lg font-bold ">
                1. WHAT INFORMATION DO WE COLLECT?
              </h2>
              <h6 className="font-medium text-base" id="personalinfo">
                Personal information you disclose to us
              </h6>
              <p className="italic">
                <span className="font-medium">In Short:</span> We collect
                personal information that you provide to us.
              </p>
              <p>
                We collect personal information that you voluntarily provide to
                us when you register on the Services, express an interest in
                obtaining information about us or our products and Services,
                when you participate in activities on the Services, or otherwise
                when you contact us.
              </p>
              <p>
                <span className="font-medium">
                  Personal Information Provided by You.
                </span>{" "}
                The personal information that we collect depends on the context
                of your interactions with us and the Services, the choices you
                make, and the products and features you use. The personal
                information we collect may include the following:
              </p>
              <ul className="list-disc ml-10 space-y-4">
                <li>names</li>
                <li>phone numbers</li>
                <li>email addresses</li>
                <li>contact preferences</li>
                <li>billing addresses</li>
                <li>debit/credit card numbers</li>
              </ul>
              <p>
                <span className="font-medium" id="sensitiveinfo">
                  Sensitive Information.
                </span>{" "}
                When necessary, with your consent or as otherwise permitted by
                applicable law, we process the following categories of sensitive
                information:
              </p>
              <ul className="list-disc ml-10 space-y-4">
                <li>social security numbers or other government identifiers</li>
                <li>data about a person's sex life or sexual orientation</li>
                <li>health data</li>
              </ul>
              <p>
                <span className="font-medium">Payment Data.</span> We may
                collect data necessary to process your payment if you choose to
                make purchases, such as your payment instrument number, and the
                security code associated with your payment instrument. All
                payment data is handled and stored by razor pay. You may find
                their privacy notice link(s) here:
                <a
                  href="https://razorpay.com/privacy/"
                  className="underline text-[#3de3b7]"
                >
                  https://razorpy.com/privacy/
                </a>
                .
              </p>
              <p>
                <span className="font-medium">Application Data.</span> If you
                use our application(s), we also may collect the following
                information if you choose to provide us with access or
                permission:
              </p>
              <ul className="list-disc space-y-4 ml-10">
                <li>
                  Geolocation Information. We may request access or permission
                  to track location-based information from your mobile device,
                  either continuously or while you are using our mobile
                  application(s), to provide certain location-based services. If
                  you wish to change our access or permissions, you may do so in
                  your device's settings.
                </li>
                <li>
                  Mobile Device Access. We may request access or permission to
                  certain features from your mobile device, including your
                  mobile device's calendar, camera, contacts, microphone, sms
                  messages, storage, reminders, and other features. If you wish
                  to change our access or permissions, you may do so in your
                  device's settings.
                </li>
                <li>
                  Mobile Device Data. We automatically collect device
                  information (such as your mobile device ID, model, and
                  manufacturer), operating system, version information and
                  system configuration information, device and application
                  identification numbers, browser type and version, hardware
                  model Internet service provider and/or mobile carrier, and
                  Internet Protocol (IP) address (or proxy server). If you are
                  using our application(s), we may also collect information
                  about the phone network associated with your mobile device,
                  your mobile device’s operating system or platform, the type of
                  mobile device you use, your mobile device’s unique device ID,
                  and information about the features of our application(s) you
                  accessed.
                </li>
                <li>
                  Push Notifications. We may request to send you push
                  notifications regarding your account or certain features of
                  the application(s). If you wish to opt out from receiving
                  these types of communications, you may turn them off in your
                  device's settings.
                </li>
              </ul>
              <p>
                This information is primarily needed to maintain the security
                and operation of our application(s), for troubleshooting, and
                for our internal analytics and reporting purposes.
              </p>
              <p>
                All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.
              </p>

              <h2 className="text-base font-medium ">
                Information automatically collected
              </h2>
              <p className="italic">
                <span className="font-medium">In Short:</span> Some information
                — such as your Internet Protocol (IP) address and/or browser and
                device characteristics — is collected automatically when you
                visit our Services.
              </p>
              <p>
                We automatically collect certain information when you visit,
                use, or navigate the Services. This information does not reveal
                your specific identity (like your name or contact information)
                but may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our Services,
                and other technical information. This information is primarily
                needed to maintain the security and operation of our Services,
                and for our internal analytics and reporting purposes.
              </p>
              <p>
                Like many businesses, we also collect information through
                cookies and similar technologies.
              </p>
              <p>The information we collect includes:</p>
              <ul className="space-y-4 list-disc pl-10">
                <li>
                  Log and Usage Data. Log and usage data is service-related,
                  diagnostic, usage, and performance information our servers
                  automatically collect when you access or use our Services and
                  which we record in log files. Depending on how you interact
                  with us, this log data may include your IP address, device
                  information, browser type, and settings and information about
                  your activity in the Services (such as the date/time stamps
                  associated with your usage, pages and files viewed, searches,
                  and other actions you take such as which features you use),
                  device event information (such as system activity, error
                  reports (sometimes called "crash dumps"), and hardware
                  settings).
                </li>
                <li>
                  Device Data. We collect device data such as information about
                  your computer, phone, tablet, or other device you use to
                  access the Services. Depending on the device used, this device
                  data may include information such as your IP address (or proxy
                  server), device and application identification numbers,
                  location, browser type, hardware model, Internet service
                  provider and/or mobile carrier, operating system, and system
                  configuration information.
                </li>
                <li>
                  Location Data. We collect location data such as information
                  about your device's location, which can be either precise or
                  imprecise. How much information we collect depends on the type
                  and settings of the device you use to access the Services. For
                  example, we may use GPS and other technologies to collect
                  geolocation data that tells us your current location (based on
                  your IP address). You can opt out of allowing us to collect
                  this information either by refusing access to the information
                  or by disabling your Location setting on your device. However,
                  if you choose to opt out, you may not be able to use certain
                  aspects of the Services.
                </li>
              </ul>
              <h5 className="text-base font-medium">Google API</h5>
              <p>
                Our use of information received from Google APIs will adhere to
                <a
                  href="https://developers.google.com/terms/api-services-user-data-policy"
                  className="underline text-[#3de3b7]"
                >
                  Google API Services User Data Policy
                </a>
                , including the{" "}
                <a
                  href="https://developers.google.com/terms/api-services-user-data-policy#limited-use"
                  className="underline text-[#3de3b7]"
                >
                  Limited Use requirements
                </a>
                .
              </p>
            </div>
            <div className="pt-8 text-sm space-y-5" id="infouse">
              <h2 className="text-lg font-bold">
                2. HOW DO WE PROCESS YOUR INFORMATION?
              </h2>
              <p className="italic">
                <span>In Short:</span> We process your information to provide,
                improve, and administer our Services, communicate with you, for
                security and fraud prevention, and to comply with law. We may
                also process your information for other purposes with your
                consent.
              </p>
              <p className="font-medium">
                We process your personal information for a variety of reasons,
                depending on how you interact with our Services, including:
              </p>
              <ul className="list-disc space-y-4 ml-10">
                <li>
                  <span className="font-medium">
                    {" "}
                    To facilitate account creation and authentication and
                    otherwise manage user accounts.
                  </span>{" "}
                  We may process your information so you can create and log in
                  to your account, as well as keep your account in working
                  order.
                </li>
                <li>
                  <span className="font-medium">
                    To deliver and facilitate delivery of services to the user.
                  </span>{" "}
                  We may process your information to provide you with the
                  requested service.
                </li>
                <li>
                  <span className="font-medium">
                    To respond to user inquiries/offer support to users.
                  </span>{" "}
                  We may process your information to respond to your inquiries
                  and solve any potential issues you might have with the
                  requested service.
                </li>
                <li>
                  <span className="font-medium">
                    To send administrative information to you.
                  </span>{" "}
                  We may process your information to send you details about our
                  products and services, changes to our terms and policies, and
                  other similar information.
                </li>
                <li>
                  <span className="font-medium">To request feedback.</span> We
                  may process your information when necessary to request
                  feedback and to contact you about your use of our Services.
                </li>
                <li>
                  <span className="font-medium">
                    To send you marketing and promotional communications.
                  </span>{" "}
                  We may process the personal information you send to us for our
                  marketing purposes, if this is in accordance with your
                  marketing preferences. You can opt out of our marketing emails
                  at any time. For more information, see{" "}
                  <a href="#privacyrights" className="underline text-[#3de3b7]">
                    "WHAT ARE YOUR PRIVACY RIGHTS?"
                  </a>{" "}
                  below.
                </li>
                <li>
                  <span className="font-medium">
                    To evaluate and improve our Services, products, marketing,
                    and your experience.
                  </span>{" "}
                  We may process your information when we believe it is
                  necessary to identify usage trends, determine the
                  effectiveness of our promotional campaigns, and to evaluate
                  and improve our Services, products, marketing, and your
                  experience.
                </li>
                <li>
                  <span className="font-medium">To identify usage trends.</span>{" "}
                  We may process information about how you use our Services to
                  better understand how they are being used so we can improve
                  them.
                </li>
                <li>
                  <span className="font-medium">
                    To comply with our legal obligations.
                  </span>
                  We may process your information to comply with our legal
                  obligations, respond to legal requests, and exercise,
                  establish, or defend our legal rights.
                </li>
              </ul>
            </div>
            <div className="pt-8 text-sm space-y-5" id="whoshare">
              <h2 className="text-lg font-bold">
                3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </h2>
              <p className="italic">
                <span className="font-medium">In Short:</span> We may share
                information in specific situations described in this section
                and/or with the following third parties.
              </p>
              <p>
                <span className="font-medium">
                  Vendors, Consultants, and Other Third-Party Service Providers.
                </span>
                We may share your data with third-party vendors, service
                providers, contractors, or agents ("third parties") who perform
                services for us or on our behalf and require access to such
                information to do that work. We have contracts in place with our
                third parties, which are designed to help safeguard your
                personal information. This means that they cannot do anything
                with your personal information unless we have instructed them to
                do it. They will also not share your personal information with
                any organization apart from us. They also commit to protect the
                data they hold on our behalf and to retain it for the period we
                instruct.
              </p>
              <p>
                The third parties we may share personal information with are as
                follows:
              </p>
              <ul className="list-disc pl-5 space-y-4">
                <li className="space-y-3">
                  <strong>
                    Advertising, Direct Marketing, and Lead Generation
                  </strong>
                  <p>Google AdSense</p>
                </li>
                <li className="space-y-3">
                  <strong>Cloud Computing Services</strong>
                  <p>Amazon Web Services (AWS)</p>
                </li>
                <li className="space-y-3">
                  <strong>Data Backup and Security</strong>
                  <p>Amazon Glacier</p>
                </li>
                <li className="space-y-3">
                  <strong>Functionality and Infrastructure Optimization</strong>
                  <p>Amazon Web Services</p>
                </li>
                <li className="space-y-3">
                  <strong>Invoice and Billing</strong>
                  <p>razorpay</p>
                </li>
                <li className="space-y-3">
                  <strong>Web and Mobile Analytics</strong>
                  <p>Google Analytics</p>
                </li>
                <li className="space-y-3">
                  <strong>Website Hosting</strong>
                  <p>aws</p>
                </li>
              </ul>
              <p>
                We also may need to share your personal information in the
                following situations:
              </p>
              <ul>
                <li>
                  <span className="font-medium">Business Transfers.</span> We
                  may share or transfer your information in connection with, or
                  during negotiations of, any merger, sale of company assets,
                  financing, or acquisition of all or a portion of our business
                  to another company.
                </li>
                <li>
                  <span className="font-medium">
                    {" "}
                    When we use Google Maps Platform APIs.
                  </span>{" "}
                  We may share your information with certain Google Maps
                  Platform APIs (e.g., Google Maps API, Places API). Google Maps
                  uses GPS, Wi-Fi, and cell towers to estimate your location.
                  GPS is accurate to about 20 meters, while Wi-Fi and cell
                  towers help improve accuracy when GPS signals are weak, like
                  indoors. This data helps Google Maps provide directions, but
                  it is not always perfectly precise.
                </li>
              </ul>
            </div>
            <div className="pt-8 text-sm space-y-5" id="cookies">
              <h2 className="text-lg font-bold">
                4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </h2>
              <p className="italic">
                <span className="font-medium">In Short:</span>We may use cookies
                and other tracking technologies to collect and store your
                information.
              </p>
              <p>
                We may use cookies and similar tracking technologies (like web
                beacons and pixels) to gather information when you interact with
                our Services. Some online tracking technologies help us maintain
                the security of our Services and your account, prevent crashes,
                fix bugs, save your preferences, and assist with basic site
                functions.
              </p>
              <p>
                We also permit third parties and service providers to use online
                tracking technologies on our Services for analytics and
                advertising, including to help manage and display
                advertisements, to tailor advertisements to your interests, or
                to send abandoned shopping cart reminders (depending on your
                communication preferences). The third parties and service
                providers use their technology to provide advertising about
                products and services tailored to your interests which may
                appear either on our Services or on other websites.
              </p>
              <p>
                Specific information about how we use such technologies and how
                you can refuse certain cookies is set out in our Cookie Notice.
              </p>
              <h3 className="text-base font-medium ">Google Analytics</h3>
              <p>
                We may share your information with Google Analytics to track and
                analyze the use of the Services. The Google Analytics
                Advertising Features that we may use include: Remarketing with
                Google Analytics, Google Display Network Impressions Reporting
                and Google Analytics Demographics and Interests Reporting. To
                opt out of being tracked by Google Analytics across the
                Services, visit{" "}
                <a
                  href=" https://tools.google.com/dlpage/gaoptout"
                  className="underline text-[#3de3b7]"
                >
                  https://tools.google.com/dlpage/gaoptout
                </a>
                . You can opt out of Google Analytics Advertising Features
                through{" "}
                <a
                  href="https://adssettings.google.com/"
                  className="underline text-[#3de3b7]"
                >
                  Ads Settings{" "}
                </a>{" "}
                and Ad Settings for mobile apps. Other opt out means include{" "}
                <a
                  href=" http://optout.networkadvertising.org/"
                  className="underline text-[#3de3b7]"
                >
                  http://optout.networkadvertising.org/
                </a>{" "}
                and{" "}
                <a
                  href="http://www.networkadvertising.org/mobile-choice"
                  className="underline text-[#3de3b7]"
                >
                  http://www.networkadvertising.org/mobile-choice
                </a>
                . For more information on the privacy practices of Google,
                please visit the{" "}
                <a
                  href="https://policies.google.com/privacy"
                  className="underline text-[#3de3b7]"
                >
                  Google Privacy & Terms page.
                </a>
              </p>
            </div>
            <div className="pt-8 text-sm space-y-5" id="inforetain">
              <h2 className="text-lg font-bold">
                5. HOW LONG DO WE KEEP YOUR INFORMATION?
              </h2>
              <p className="italic">
                <span className="font-medium">In Short:</span> We keep your
                information for as long as necessary to fulfill the purposes
                outlined in this Privacy Notice unless otherwise required by
                law.
              </p>
              <p>
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this Privacy Notice,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting, or other legal requirements). No
                purpose in this notice will require us keeping your personal
                information for longer than the period of time in which users
                have an account with us.
              </p>
              <p>
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymize such
                information, or, if this is not possible (for example, because
                your personal information has been stored in backup archives),
                then we will securely store your personal information and
                isolate it from any further processing until deletion is
                possible.
              </p>
            </div>
            <div className="pt-8 text-sm space-y-5" id="infosafe">
              <h2 className="text-lg font-bold">
                6. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </h2>
              <p className="italic">
                <span className="font-medium">In Short:</span> We aim to protect
                your personal information through a system of organizational and
                technical security measures.
              </p>
              <p>
                We have implemented appropriate and reasonable technical and
                organizational security measures designed to protect the
                security of any personal information we process. However,
                despite our safeguards and efforts to secure your information,
                no electronic transmission over the Internet or information
                storage technology can be guaranteed to be 100% secure, so we
                cannot promise or guarantee that hackers, cybercriminals, or
                other unauthorized third parties will not be able to defeat our
                security and improperly collect, access, steal, or modify your
                information. Although we will do our best to protect your
                personal information, transmission of personal information to
                and from our Services is at your own risk. You should only
                access the Services within a secure environment.
              </p>
            </div>
            <div className="pt-8 text-sm space-y-5" id="infominors">
              <h2 className="text-lg font-bold">
                7. DO WE COLLECT INFORMATION FROM MINORS?
              </h2>
              <p className="italic">
                <span className="font-medium">In Short:</span> We do not
                knowingly collect data from or market to children under 18 years
                of age.
              </p>
              <p>
                We do not knowingly collect, solicit data from, or market to
                children under 18 years of age, nor do we knowingly sell such
                personal information. By using the Services, you represent that
                you are at least 18 or that you are the parent or guardian of
                such a minor and consent to such minor dependent’s use of the
                Services. If we learn that personal information from users less
                than 18 years of age has been collected, we will deactivate the
                account and take reasonable measures to promptly delete such
                data from our records. If you become aware of any data we may
                have collected from children under age 18, please contact us at
                contact@promarvel.com.
              </p>
            </div>
            <div className="pt-8 text-sm space-y-5" id="privacyrights">
              {" "}
              <h2 className="text-lg font-bold">
                8. WHAT ARE YOUR PRIVACY RIGHTS?
              </h2>
              <p className="italic">
                <span className="font-medium">In Short:</span> You may review,
                change, or terminate your account at any time, depending on your
                country, province, or state of residence.
              </p>
              <p>
                <span className="underline font-medium">
                  Withdrawing your consent:
                </span>{" "}
                If we are relying on your consent to process your personal
                information, which may be express and/or implied consent
                depending on the applicable law, you have the right to withdraw
                your consent at any time. You can withdraw your consent at any
                time by contacting us by using the contact details provided in
                the section{" "}
                <a href="#contact" className="underline text-[#3de3b7]">
                  "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                </a>{" "}
                below.
              </p>
              <p>
                However, please note that this will not affect the lawfulness of
                the processing before its withdrawal nor, when applicable law
                allows, will it affect the processing of your personal
                information conducted in reliance on lawful processing grounds
                other than consent.
              </p>
              <p>
                <span className="underline font-medium">
                  Opting out of marketing and promotional communications:
                </span>{" "}
                You can unsubscribe from our marketing and promotional
                communications at any time by clicking on the unsubscribe link
                in the emails that we send, or by contacting us using the
                details provided in the section{" "}
                <a href="#contact" className="underline text-[#3de3b7]">
                  "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                </a>{" "}
                below. You will then be removed from the marketing lists.
                However, we may still communicate with you — for example, to
                send you service-related messages that are necessary for the
                administration and use of your account, to respond to service
                requests, or for other non-marketing purposes.
              </p>
              <h3 className="text-base font-medium">Account Information</h3>
              <p>
                If you would at any time like to review or change the
                information in your account or terminate your account, you can:
              </p>
              <ul className="list-disc ml-10 space-y-4">
                <li>
                  Log in to your account settings and update your user account.
                </li>
              </ul>
              <p>
                Upon your request to terminate your account, we will deactivate
                or delete your account and information from our active
                databases. However, we may retain some information in our files
                to prevent fraud, troubleshoot problems, assist with any
                investigations, enforce our legal terms and/or comply with
                applicable legal requirements.
              </p>
              <p>
                <span className="underline font-medium">
                  Cookies and similar technologies:
                </span>{" "}
                Most Web browsers are set to accept cookies by default. If you
                prefer, you can usually choose to set your browser to remove
                cookies and to reject cookies. If you choose to remove cookies
                or reject cookies, this could affect certain features or
                services of our Services.
              </p>
              <p>
                If you have questions or comments about your privacy rights, you
                may email us at privacy@promarvel.com.
              </p>
            </div>
            <div className="pt-8 text-sm space-y-5" id="DNT">
              {" "}
              <h2 className="text-lg font-bold">
                9. CONTROLS FOR DO-NOT-TRACK FEATURES
              </h2>
              <p>
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track ("DNT") feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. At this stage, no uniform technology standard for
                recognizing and implementing DNT signals has been finalized. As
                such, we do not currently respond to DNT browser signals or any
                other mechanism that automatically communicates your choice not
                to be tracked online. If a standard for online tracking is
                adopted that we must follow in the future, we will inform you
                about that practice in a revised version of this Privacy Notice.
              </p>
            </div>
            <div className="pt-8 text-sm space-y-5" id="policyupdates">
              {" "}
              <h2 className="text-lg font-bold">
                10. DO WE MAKE UPDATES TO THIS NOTICE?
              </h2>
              <p className="italic">
                <span className="font-medium">In Short:</span> Yes, we will
                update this notice as necessary to stay compliant with relevant
                laws.
              </p>
              <p>
                We may update this Privacy Notice from time to time. The updated
                version will be indicated by an updated "Revised" date at the
                top of this Privacy Notice. If we make material changes to this
                Privacy Notice, we may notify you either by prominently posting
                a notice of such changes or by directly sending you a
                notification. We encourage you to review this Privacy Notice
                frequently to be informed of how we are protecting your
                information.
              </p>
            </div>
            <div className="pt-8 text-sm space-y-5" id="contact">
              {" "}
              <h2 className="text-lg font-bold">
                11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </h2>
              <p>
                If you have questions or comments about this notice, you may
                email us at contact@promarvel.com or contact us by post at:
              </p>
              <p>promarvel solutions opc private limited</p>
              <div>
                <div className="border w-20 border-white"></div>
                <p className="space-0">Hyderabad, Telangana</p>
                <p>India </p>
              </div>
            </div>
            <div className="pt-8 text-sm space-y-5" id="request">
              {" "}
              <h2 className="text-lg font-bold">
                12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </h2>
              <p>
                You have the right to request access to the personal information
                we collect from you, details about how we have processed it,
                correct inaccuracies, or delete your personal information. You
                may also have the right to withdraw your consent to our
                processing of your personal information. These rights may be
                limited in some circumstances by applicable law. To request to
                review, update, or delete your personal information, please fill
                out and submit a{" "}
                <a
                  href="https://app.termly.io/notify/3c48b622-dd7f-4242-955b-366ad7ec3265"
                  className="underline text-[#3de3b7]"
                >
                  data subject access request
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Policypage;
